<!-- 报警分析 -->
<template>
    <div class="page_container" v-loading="loading">
        <div class="box_head1">
            <span>报警频次分析</span>
            <div class="flex align_center mg_left_50">
                <!-- dimensionTrendSelect3 -->
                <el-cascader  size="medium" v-model="organizationValue" placeholder="请选择组织维度" :options="organizationAr"
                    ref="demoCascader" :props="defaultProps" class="el_cascader_width  mg_right_10" @change="handleChangeCascader"
                    :show-all-levels="false" v-if="tabIndex==1 ||tabIndex==2" collapse-tags clearable filterable></el-cascader>
                <el-select v-model="equipmentTypeValue" placeholder="请选择设备类型" multiple collapse-tags
                    class="dimensionTrendSelect2 mg_right_10" v-if="tabIndex==2">
                    <el-option v-for="item in equipmentTypeAr" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="dataFrameValue" placeholder="时间维度" class="width_110 mg_right_10">
                    <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-date-picker value-format="timestamp" v-model="timeValue" type="daterange" range-separator="~" start-placeholder="开始日期"
                    end-placeholder="结束日期" class="width_270 mg_right_10" :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class="" size="medium" @click="query()">查询 </el-button>
            </div>
        </div>
        <!-- <div class="box_head1">报警频次分析</div> -->
        <div class="flex flex_column bgc_FFFFFF pdl20 pdr20">
            <!-- 导航 -->
            <div class="bgc_FFFFFF mg_top_20 flex fn_size14 height_44">
                <div v-for="item in tabArray" :key="item.id" :class="tabIndex==item.id?'tab_active':''"
                    class="tab flex justify_center   align_center" @click="changeTab(item.id)">{{item.label}}</div>
            </div>

            <el-carousel indicator-position="outside" :interval="8000" :autoplay="true" height="120px"
                style="width: 100%;" @change="changeAlarmInfo" class="mg_top_20">
                <el-carousel-item v-for="(item,index) in Math.ceil(alarmAnalysInfo.length/4)" :key="index"
                    style="width: 100%;height: 100%;padding-left: 46px;" class=" flex">
                    <!-- Math.ceil返回大于等于参数x的最小整数,即对浮点数向上取整 -->
                    <!-- slice() 方法可从已有的数组中返回选定的元素 -->
                    <div class="box1 mg_left_30"
                        v-for="(items,index1) in alarmAnalysInfo.slice(index*4,index*4+4)" :key="index1">
                        <div class="box_1" :class="'bg_color'+index1">
                            <div class="fn_size16">{{items.name}}</div>
                            <div class="fn_size20 mg_top_17">{{items.value}}</div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>


            <div class="mg_top_20  color_1A1A1A">
                <!-- <div class="flex justify_between">
                    <div class="box_1 bgc_03CABE">
                        <div class="fn_size16">近7天报警数：</div>
                        <div class="fn_size20 mg_top_17">{{alarmAnalysInfo.alarmNum}}</div>
                    </div>
                    <div class="box_1 bgc_FEA313">
                        <div class="fn_size16">近7天最多报警日：</div>
                        <div class="fn_size20 mg_top_17">{{alarmAnalysInfo.alarmDate}}</div>
                    </div>
                    <div class="box_1 bgc_657798">
                        <div class="fn_size16"> 近7天严重报警数：</div>
                        <div class="fn_size20 mg_top_17">{{alarmAnalysInfo.alarmLevelNum}}</div>
                    </div>
                    <div class="box_1 bgc_F75753">
                        <div class="fn_size16"> 近7天频次最高报警：</div>
                        <div class="fn_size20 mg_top_17">{{alarmAnalysInfo.alarmType}}</div>
                    </div>
                </div> -->
                <!-- 下拉查询框 -->
                <!-- <div class="flex mg_top_20">
                    <el-cascader v-model="organizationValue" placeholder="请选择组织维度" :options="organizationAr"
                        ref="demoCascader" :props="defaultProps" class="dimensionTrendSelect3  mg_right_10"
                        @change="handleChangeCascader" :show-all-levels="false" v-if="tabIndex==1 ||tabIndex==2"
                        collapse-tags></el-cascader>

                    <el-select v-model="equipmentTypeValue" placeholder="请选择设备类型" multiple collapse-tags
                        class="dimensionTrendSelect2 mg_right_10" v-if="tabIndex==2">
                        <el-option v-for="item in equipmentTypeAr" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="dataFrameValue" placeholder="时间维度" class="dimensionTrendSelect2 mg_right_10">
                        <el-option v-for="item in dataFrame" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker v-model="timeValue" type="datetimerange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期" class="width_270 mg_right_10" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" class="dimensionSelectButton flex align_center justify_center"
                        @click="query()">查询 </el-button>
                </div> -->
                <!-- echarts图 -->
                <div class="diy_div mg_top_20">
                    <div id="chartColumn" class=" height_100 width_100"></div>
                </div>

                <div class="flex align_center mg_top_10 box_head1">
                    <!-- 分析维度选择框 -->
                    <el-select v-model="analysisDimensionValue" placeholder="分析维度"
                        class="dimensionTrendSelect2 mg_right_10">
                        <el-option v-for="item in analysisDimensionAr" :key="item.id" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="primary" size="medium" @click="query2()">查询
                    </el-button>
                </div>

                <div class="diy_div mg_top_20">
                    <div id="chartColumn2" class=" height_100 width_100"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    // import {
    // } from "@/api/api_alarm";
    import {
        alarmAnalysisStatistics,
        alarmAnalysisTrend,
        alarmAnalysisBarChart,
        alarmAnalysisOrganizationSelect,
        alarmAnalysisEquipmentTypeSelect,
        alarmAnalysisDimensionalitySelect
    } from "@/api/api_deviceManagement";
    export default {
        data() {
            return {
                defaultProps: {
                    // children: 'children',
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true,
                    emitPath: false, // 只返回选中节点的值
                    checkStrictly: true //多选任一级
                },
                value: '',
                tabIndex: 1,
                alarmAnalysInfo: [],
                organizationValue: [],
                defaultExpandKeys: [],
                organizationAr: [],
                standardArr: [],
                equipmentTypeValue: [],
                equipmentTypeAr: [],
                timeValue: [new Date(new Date().toLocaleDateString()).getTime(), new Date().getTime()],
                dataFrameValue: '1',
                dataFrame: [{
                    value: '1',
                    label: '日'
                }, {
                    value: '2',
                    label: '月'
                }],
                analysisDimensionValue: '',
                analysisDimensionAr: [],
                tabArray: [{
                        id: '1',
                        label: '组织维度'
                    }, {
                        id: '2',
                        label: '设备类型维度'
                    },
                    // {
                    //     id: '3',
                    //     label: '工段'
                    // },
                    // {
                    //     id: '4',
                    //     label: '分厂'
                    // }
                ],
                currentID: '',
                checkData: {},
                permission: {},
                loading: true,
                // alarmInfo: {},
                // frameValue: [],

            }
        },
        mounted() {
            // this.drawShape();
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.alarmAnalysisOrganizationSelect()
            // this.alarmAnalysisTrend();
            // this.alarmAnalysisBarChart();
            // this.alarmAnalysisDimensionalitySelect()
        },
        methods: {
            //tab
            changeTab(id) {
                // console.log(id)
                this.alarmAnalysInfo = [];
                this.tabIndex = id;
                // this.alarmAnalysisStatistics()
                // if (this.tabIndex == 1) { //设备
                //     this.currentID = this.equipmentVlue.join(',')
                // } else if (this.tabIndex == 2) { //设备类型维度
                //     this.currentID = this.equipmentTypeValue;

                // }
                if (this.tabIndex == 1) {
                    this.equipmentTypeValue = []
                }

                this.alarmAnalysisStatistics()
                this.alarmAnalysisTrend();
                this.alarmAnalysisBarChart();

            },
            handleChangeCascader() {
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    return
                }
                // console.log(this.organizationValue)
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                // console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    // console.log(obj[i].data)
                    // if (obj[i].data.Type == 'E') {
                    //     var ceshi = {
                    //         Type: 'E',
                    //         ID: '',
                    //     }
                    //     ceshi.ID = obj[i].data.ID;
                    //     currentData.push(ceshi)

                    // } else {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)

                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                // console.log('checkData', this.checkData);
                // console.log(this.organizationValue)
                this.alarmAnalysisEquipmentTypeSelect();
                this.alarmAnalysisDimensionalitySelect();
            },

            async alarmAnalysisOrganizationSelect() {
                var param = {
                    permission: this.permission
                };
                const selectRes = await alarmAnalysisOrganizationSelect(param);
                this.organizationAr = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];

                // this.organizationValue = selectRes.data.organizationPermission[0].ID;
                // this.organizationValue[0] = '100027'

                this.alarmAnalysisEquipmentTypeSelect();
                this.alarmAnalysisDimensionalitySelect();
                this.alarmAnalysisStatistics();
                this.alarmAnalysisTrend();
                setTimeout(() => {
                    this.alarmAnalysisBarChart();
                }, 1000)
                this.loading = false

            },
            // 选择设备类型
            async alarmAnalysisEquipmentTypeSelect() {
                var param = {};
                param.organization = this.checkData;
                const selectRes = await alarmAnalysisEquipmentTypeSelect(param);
                this.equipmentTypeAr = selectRes.data;
                // this.equipmentTypeValue = selectRes.data.equipmentType[0].id
            },
            // 分析维度选择框
            async alarmAnalysisDimensionalitySelect() {
                var param = {};
                param.organization = this.checkData;
                const selectRes = await alarmAnalysisDimensionalitySelect(param);
                this.analysisDimensionAr = selectRes.data.dimensionality;
                this.analysisDimensionValue = selectRes.data.dimensionality[0].id;
                // this.alarmAnalysisBarChart();

            },
            query() {
                // if (this.tabIndex == 1) { //设备
                //     this.currentID = this.equipmentVlue.join(',')
                // } else if (this.tabIndex == 2) { //设备类型维度
                //     this.currentID = this.equipmentTypeValue; //设备类型ID
                // }
                this.alarmAnalysInfo = [];
                this.alarmAnalysisStatistics();
                this.alarmAnalysisTrend();
                this.alarmAnalysisBarChart();
                // }
            },
            query2() {
                this.alarmAnalysisBarChart();
            },
            async alarmAnalysisStatistics() {
                // this.alarmInfo =[]
                var param = {
                    // permission:'',
                    organization: {
                        // "Type": "P",
                        // "ID": [1],
                        Type: this.checkData.Type,
                        ID: this.checkData.ID
                        // "CategoryID": [1, 2],
                    }
                };
                // console.log(this.equipmentTypeValue.join(','))
                if (this.equipmentTypeValue.join(',') != '') {
                    param.organization.CategoryID = this.equipmentTypeValue;
                }
                const selectRes = await alarmAnalysisStatistics(param);
                this.alarmAnalysInfo = selectRes.data;

            },
            changeAlarmInfo() {},
            async alarmAnalysisTrend() {
                this.chartColumn = echarts.init(document.getElementById('chartColumn'));
                this.chartColumn.showLoading({
                    text: '统计中，请稍候...',
                    // maskColor: 'rgba(3,3,8,0.5)',
                    // textColor: '#fff600'
                });
                var testData = {
                    // id: this.currentID,
                    Type: this.checkData.Type,
                    ID: this.checkData.ID
                }
                if (this.equipmentTypeValue.join(',') != '') {
                    testData.CategoryID = this.equipmentTypeValue;
                }
                var param = {
                    dateType: this.dataFrameValue, //日月年
                    startDate: this.timeValue[0],
                    endDate: this.timeValue[1],
                    organization: testData,
                    // permission:''
                };
                const selectRes = await alarmAnalysisTrend(param);
                var lineA = {
                    legend: {
                        x: 'center',
                        y: 'bottom',
                        bottom: '10%', //距离下边距
                        /*  padding:-5, */
                        data: []
                    },
                    xAxis: [],
                    series: []
                }
                 echarts.init(document.getElementById('chartColumn')).dispose(); //销毁echarts
                if (selectRes.data) {
                    for (var i = 0; i < selectRes.data.length; i++) {
                        var testData = {
                            name: '',
                            type: 'line',
                            // stack: '',
                            symbol: 'circle',
                            symbolSize: 8, //拐点圆的大小
                            data: []
                        }
                        testData.name = selectRes.data[i].name + ' ' + selectRes.data[i].lenged[0];
                        testData.data = selectRes.data[i].num;
                        lineA.series[i] = testData
                        lineA.legend.data[i] = selectRes.data[i].lenged[0];
                        lineA.xAxis = selectRes.data[0].time;
                    }
                    this.drawChartColumn(lineA);
                }
                this.chartColumn.hideLoading()

            },
            async alarmAnalysisBarChart() {
                this.chartColumn2 = echarts.init(document.getElementById('chartColumn2'));
                this.chartColumn2.showLoading({
                    text: '统计中，请稍候...',
                    // maskColor: 'rgba(3,3,8,0.5)',
                    // textColor: '#fff600'
                });
                var testData = {
                    // id: this.currentID,
                    Type: this.checkData.Type,
                    ID: this.checkData.ID
                }
                if (this.equipmentTypeValue.join(',') != '') {
                    testData.CategoryID = this.equipmentTypeValue;
                }
                var param = {
                    analysisDimensionality: this.analysisDimensionValue, //分析维度
                    dateType: this.dataFrameValue,
                    startDate: this.timeValue[0],
                    endDate: this.timeValue[1],
                    organization: testData

                };
                echarts.init(document.getElementById('chartColumn2')).dispose(); //销毁echarts
                const selectRes = await alarmAnalysisBarChart(param);
                if (!selectRes.data) {
                    return
                }
                var lineB = {
                    legend: {
                        data: [],
                        x: 'center',
                        y: 'bottom',
                        bottom: '10%', //距离下边距
                    },
                    xAxis: [],
                    series: []
                }
                if (selectRes.data.length != 0) {
                    lineB.legend.data = selectRes.data[0].lenged;
                    lineB.xAxis = selectRes.data[0].time;
                    var list = selectRes.data;
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].value) {
                            var listValue = list[i].value;
                            for (var j = 0; j < listValue.length; j++) {
                                var testData2 = {
                                    name: '',
                                    type: 'bar',
                                    stack: '',
                                    // stack: 'total',
                                    id: '',
                                    barWidth: '60px',
                                    barMaxWidth: '60px',
                                    data: [],
                                    label: '',
                                    // id:'',
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowColor: 'rgba(0,0,0,0.3)'
                                        }
                                    }
                                }
                                testData2.stack = 'total' + i;
                                testData2.id = list[i].name + ' ' + listValue[j].typeName
                                // console.log(listValue[j])
                                testData2.name = listValue[j].typeName;
                                testData2.data = listValue[j].num;
                                // testData2.id = list[i].name;
                                lineB.series.push(testData2);
                            }
                        } else {
                            var testData2 = {
                                name: '',
                                type: 'bar',
                                stack: '',
                                barWidth: '60px',
                                barMaxWidth: '60px',
                                data: [],
                            }
                            testData2.stack = 'total' + i;
                            testData2.name = list[i].lenged[0];
                            testData2.id = list[i].name + ' ' + list[i].lenged[0]
                            testData2.data = list[i].num;
                            lineB.series[i] = testData2;
                            lineB.legend.data[i] = list[0].lenged[0];
                            lineB.xAxis = list[0].time;
                        }

                    }
                    console.log(lineB)
                    this.drawChartColumn2(lineB);

                }
                this.chartColumn2.hideLoading()

            },

            //接口-结束
            //echarts
            drawChartColumn(data) {
                /**维度趋势选择 */
                echarts.init(document.getElementById('chartColumn')).dispose(); //销毁echarts
                this.chartColumn = echarts.init(document.getElementById('chartColumn'));
                this.chartColumn.setOption({
                    tooltip: {
                        trigger: 'axis',
                        textStyle: {
                            align: 'left'
                        },
                    },
                    legend: data.legend,
                    // legend: {
                    //     x: 'center',
                    //     y: 'bottom',
                    //     bottom: '10%', //距离下边距
                    //     /*  padding:-5, */
                    //     data: ['tokyo', 'london']
                    // },
                    grid: {
                        left: '3%',
                        right: '3%',
                        top: '10%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true //x轴左右留白
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#E3E4E6',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                        },
                        data: data.xAxis
                        // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov',
                        //     'Dec'
                        // ]
                    },
                    yAxis: {
                        type: 'value',
                        // min: 0, //y轴的最小值
                        // max: 30, //y轴最大值 
                        // interval: 10, //值之间的间隔
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                        }
                    },
                    series: data.series
                });
                // this.chartColumn.hideLoading()
            },
            drawChartColumn2(data) {
                /**堆叠条形图 */
                echarts.init(document.getElementById('chartColumn2')).dispose(); //销毁echarts
                this.chartColumn2 = echarts.init(document.getElementById('chartColumn2'));
                this.chartColumn2.setOption({
                    color: ['#5470C6', '#85C766', '#FAC858', '#EE6666'],
                    tooltip: {
                        // trigger: 'axis',
                        // axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        //     type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        // }
                        formatter: function (params) {
                            // console.log(params)
                            return params.name + '<br />' + params.seriesId + '：' + params.value
                        }
                    },
                    legend: data.legend,
                    grid: {
                        left: '3%',
                        right: '3%',
                        top: '10%',
                        bottom: '10%',
                        containLabel: true
                    },
                    yAxis: {
                        type: 'value',
                        // min: 0, //y轴的最小值
                        // max: 50, //y轴最大值 
                        // interval: 10, //值之间的间隔
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true //x轴左右留白
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#E3E4E6',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#808080',
                            fontSize: 12,
                        },
                        data: data.xAxis
                    },
                    series: data.series
                });
                // this.chartColumn2.hideLoading()
            }
        }
    }
</script>

<style scoped>
    .alarmAnalysisHeader {
        width: 1862px;
        height: 40px;
        padding-left: 20px;
        background-color: #E2F0FE;
        border: 1px solid #CCDBE5;
        font-size: 16px;
        font-family: Source Han Sans SC;
    }

    .dimensionTextip {
        width: 400px;
        height: 98px;
        margin-right: 68px;
    }

    .el-radio-button__inner {
        width: 140px;
        height: 36px;
        font-size: 16px;
        margin-right: 10px;
        background-color: #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 0;
    }

    .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 0;
    }

    .dimensionTrendSelect {
        width: 160px;
        height: 36px;
    }

    .dimensionTrendSelect2 {
        width: 170px;
        height: 36px;
    }

    .dimensionTrendSelect3 {
        width: 180px;
        height: 36px;
        line-height: 36px;
    }

    .diy_div {
        /* width: 1831px; */
        height: 300px;
    }

    .dimensionSelectButton {
        width: 110px;
        height: 36px;
        background: #009BFD;
    }

    .tab {
        width: 140px;
        height: 36px;
        background: #E6E6E6;
        color: #1A1A1A;
        margin-right: 10px;
    }

    .tab_active {
        background: #009BFD;
        color: #FFFFFF;
    }

    .box_1 {
        width: 400px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
    }

    .box1 {
        /* width: 400px;
        height: 100px; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bg_color0 {
        background: #03CABE;
    }

    .bg_color1 {
        background: #FEA313;
    }

    .bg_color2 {
        background: #657798;
    }

    .bg_color3 {
        background: #F75753;
    }
</style>

<style lang="less" scoped>
</style>